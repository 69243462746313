<script>
import VueUtterances from "vue-utterances";

export default {
   components: {
      VueUtterances
   }
}
</script>

<template>
   <div class="hstack" id="menu">
      <span style="font-weight: bold; font-family: Godo; font-size: 1.3rem;"></span>
      <button type="button" class="btn btn-light ms-auto">
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
               d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z">
            </path>
         </svg>
         더보기
      </button>
   </div>
   <div class="hstack" id="menu">
      <span style="font-weight: bold; font-family: Godo; font-size: 1.3rem;">음악 아티스트</span>
      <button type="button" class="btn btn-light ms-auto">
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
               d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z">
            </path>
         </svg>
         더보기
      </button>
   </div>
      <div class="row align-items-center">
   <div class="card" style="width: 18rem;">
      <img src="" class="card-img-top" alt="...">
      <div class="card-body">
         <h5 class="card-title">Card title</h5>
         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
         <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
   </div>
   <div class="card" style="width: 18rem;">
      <img src="" class="card-img-top" alt="...">
      <div class="card-body">
         <h5 class="card-title">Card title</h5>
         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
         <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
   </div>
   <div class="card" style="width: 18rem;">
      <img src="" class="card-img-top" alt="...">
      <div class="card-body">
         <h5 class="card-title">Card title</h5>
         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
         <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
   </div>
   <div class="card" style="width: 18rem;">
      <img src="" class="card-img-top" alt="...">
      <div class="card-body">
         <h5 class="card-title">Card title</h5>
         <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
            content.</p>
         <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
   </div>
   </div>
   <div class="hstack" id="menu">
      <span style="font-weight: bold; font-family: Godo; font-size: 1.3rem;"></span>
      <button type="button" class="btn btn-light ms-auto">
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
               d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z">
            </path>
         </svg>
         더보기
      </button>
   </div>
      <vue-utterances repo="hyeonho10/Muser" theme="github-light" issue-term="pathname" />
</template>

<style>
#menu {
   margin-left: 0.5rem;
   margin-right: 0.5rem;
   margin-top: 1rem;
}
</style>